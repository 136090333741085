define('ember-bootstrap/components/bs-navbar/toggle', ['exports', 'ember-bootstrap/components/base/bs-navbar/toggle'], function (exports, _toggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _toggle.default.extend({
    classNames: ['navbar-toggler'],
    classNameBindings: ['alignmentClass'],

    /**
     * Defines the alignment of the toggler. Valid values are 'left' and 'right'
     * to set the `navbar-toggler-*` class.
     *
     * @property align
     * @type String
     * @default null
     * @public
     */
    align: null,

    alignmentClass: computed('align', function () {
      var align = this.get('align');

      if (align) {
        return 'navbar-toggler-' + align;
      }
    }).readOnly()
  });
});