define('ember-flatpickr/components/ember-flatpickr', ['exports', 'ember-diff-attrs'], function (exports, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var assign = Ember.assign;
  var Component = Ember.Component;
  var on = Ember.on;
  var run = Ember.run;
  exports.default = Component.extend({
    tagName: 'input',
    type: 'text',
    attributeBindings: ['placeholder', 'type'],
    date: null,
    flatpickrRef: null,

    setupComponent: on('init', function () {
      // Require that users pass a date
      (true && !(this.get('date') !== undefined) && Ember.assert('{{ember-flatpickr}} requires a `date` to be passed as the value for flatpickr.', this.get('date') !== undefined));

      // Require that users pass an onChange

      (true && !(this.get('onChange') !== undefined) && Ember.assert('{{ember-flatpickr}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));

      // Pass all values and setup flatpickr

      run.scheduleOnce('afterRender', this, function () {
        var options = this.getProperties(Object.keys(this.attrs));

        // Add defaultDate, change and close handlers
        assign(options, {
          defaultDate: this.get('date'),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });

        var flatpickrRef = flatpickr(this.element, options);

        if (this.get('appendDataInput')) {
          this.element.setAttribute('data-input', '');
        }

        this._setDisabled(this.get('disabled'));

        this.set('flatpickrRef', flatpickrRef);
      });
    }),

    didReceiveAttrs: (0, _emberDiffAttrs.default)('date', 'disabled', 'locale', 'maxDate', 'minDate', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (changedAttrs && changedAttrs.date) {
        var _changedAttrs$date = _slicedToArray(changedAttrs.date, 2),
            oldDate = _changedAttrs$date[0],
            newDate = _changedAttrs$date[1];

        if (typeof newDate !== 'undefined' && oldDate !== newDate) {
          this.element._flatpickr.setDate(newDate);
        }
      }

      if (changedAttrs && changedAttrs.disabled) {
        var _changedAttrs$disable = _slicedToArray(changedAttrs.disabled, 2),
            oldDisabled = _changedAttrs$disable[0],
            newDisabled = _changedAttrs$disable[1];

        if (typeof newDisabled !== 'undefined' && oldDisabled !== newDisabled) {
          this._setDisabled(newDisabled);
        }
      }

      if (changedAttrs && changedAttrs.locale) {
        var _changedAttrs$locale = _slicedToArray(changedAttrs.locale, 2),
            oldLocale = _changedAttrs$locale[0],
            newLocale = _changedAttrs$locale[1];

        if (oldLocale !== newLocale) {
          this.element._flatpickr.destroy();
          this.setupComponent();
        }
      }

      if (changedAttrs && changedAttrs.maxDate) {
        var _changedAttrs$maxDate = _slicedToArray(changedAttrs.maxDate, 2),
            oldMaxDate = _changedAttrs$maxDate[0],
            newMaxDate = _changedAttrs$maxDate[1];

        if (oldMaxDate !== newMaxDate) {
          this.element._flatpickr.set('maxDate', newMaxDate);
        }
      }

      if (changedAttrs && changedAttrs.minDate) {
        var _changedAttrs$minDate = _slicedToArray(changedAttrs.minDate, 2),
            oldMinDate = _changedAttrs$minDate[0],
            newMinDate = _changedAttrs$minDate[1];

        if (oldMinDate !== newMinDate) {
          this.element._flatpickr.set('minDate', newMinDate);
        }
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this.element._flatpickr.destroy();
    },


    /**
     * When the date is changed, update the value and send 'onChange' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onChange: function _onChange(selectedDates, dateStr, instance) {
      this.sendAction('onChange', selectedDates, dateStr, instance);
    },


    /**
     * When the flatpickr is closed, fire the 'onClose' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onClose: function _onClose(selectedDates, dateStr, instance) {
      this.sendAction('onClose', selectedDates, dateStr, instance);
    },


    /**
     * When the flatpickr is opened, fire the 'onOpen' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onOpen: function _onOpen(selectedDates, dateStr, instance) {
      this.sendAction('onOpen', selectedDates, dateStr, instance);
    },


    /**
     * When the flatpickr is ready, fire the 'onReady' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onReady: function _onReady(selectedDates, dateStr, instance) {
      this.sendAction('onReady', selectedDates, dateStr, instance);
    },


    /**
     * Set disabled for the correct input, handling altInput weirdness
     * @param {boolean} disabled Disabled or not
     * @private
     */
    _setDisabled: function _setDisabled(disabled) {
      if (this.get('altInput')) {
        this.element.nextSibling.disabled = disabled;
      } else {
        this.element.disabled = disabled;
      }
    }
  });
});